<script setup>
import { Head, Link, useForm } from '@inertiajs/vue3';
import AuthenticationCard from '@/Components/AuthenticationCard.vue';
import AuthenticationCardLogo from '@/Components/AuthenticationCardLogo.vue';
import Checkbox from '@/Components/Checkbox.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import { ref } from 'vue'
import LoginOption from '@/Pages/Guest/Components/LoginOption.vue'
import LoginForm from '@/Pages/Guest/Components/LoginForm.vue'
import AppLayout from '@/Pages/Guest/Components/AppLayout.vue';

const props = defineProps({
    canResetPassword: Boolean,
    status: String,
    type: String,
});
const selectedType = ref(props.type)

</script>

<template>
    <AppLayout>
        <main class="bg-dark flex items-center justify-center py-36 px-4 sm:px-6 lg:px-8">
            <div class="max-w-md w-full space-y-8">
                <div class="text-center">
                    <h2 class="text-3xl font-bold">Welcome Back</h2>
                    <p class="mt-2 text-foreground-secondary">Choose your account type to continue</p>
                </div>

                <div class="mt-8 space-y-4">
                    <LoginOption
v-if="selectedType !== 'receiver'" title="Music Sender"
                        description="For artists, labels, and publicists sending promotional content" icon="SendIcon"
                        :selected="selectedType === 'sender'" @click="selectedType = 'sender'" />

                    <LoginOption
v-if="selectedType !== 'sender'" title="Music Receiver"

                        description="For journalists, radio stations, and playlist curators" icon="ReceiveIcon"
                        :selected="selectedType === 'receiver'" @click="selectedType = 'receiver'" />
                </div>

                <LoginForm v-if="selectedType" :user-type="selectedType" :status="status" @back="selectedType = ''" />
            </div>
        </main>
    </AppLayout>
</template>
